import React from "react";
import '../../components/Layout/layoutGlossary.css';

const CustomSubSection = ({ children }) => {
  return (
    <div className="mtop-1">
      {children}
    </div>
  );
};

export default CustomSubSection;
